import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import QANDA from "../components/qanda"

const StyledContainer = styled.div` 
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 2rem auto;
`;


export default function FAQPage({ data }) {
  const faqs = data.faqs.nodes

  return (
    <>
    <SEO title="FAQ" />
    <StyledContainer>
    <QANDA faqs={faqs} />
    </StyledContainer>
    </>
  )
}

export const query = graphql` 
  query faqsQuery {
    faqs: allSanityFaqs {
      nodes {
        id
        question
        answer
        event_key
      }
    }
  }
`;